/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSolutionQuery } from '@querys/useSolutionQuery';
import { useSolutionSubcategoryQuery } from '@querys/useSolutionSubcategoryQuery';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { BackButton, ForwardButton } from '@components/UI/navButtons';
import Container from '@components/financeForm/businessHomeLoans/container';
import FastContainer from '@components/financeForm/fastApproval/fastProviderContainer';

const SolutionSubcategoryTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, slug } = page || {};
  const { solutions } = useSolutionQuery();
  const { subcats } = useSolutionSubcategoryQuery();

  const allItems = [...solutions, ...subcats];

  const [currentSolution, setCurrentSolution] = useState(0);
  const [previousSolution, setPreviousSolution] = useState(0);
  const [nextSolution, setNextSolution] = useState(0);

  useEffect(() => {
    const currentSolutionIndex = allItems.findIndex(
      (solution) => solution.slug.current === slug.current
    );
    setCurrentSolution(currentSolutionIndex);
    setPreviousSolution(currentSolutionIndex - 1);
    setNextSolution(currentSolutionIndex + 1);
  }, []);

  return (
    <Layout>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
      <div className='mt-space-m px-gutter flex flex-col md:flex-row justify-between '>
        {currentSolution !== 0 && (
          <BackButton
            linkText='Previous service'
            linkUrl={`/solutions/${allItems[previousSolution]?.slug?.current}`}
            extraClasses='w-full blockH3 pl-4 '
          />
        )}
        {nextSolution < allItems.length && (
          <ForwardButton
            linkText='Next service'
            linkUrl={`/solutions/${allItems[nextSolution]?.slug?.current}`}
            extraClasses='blockH3 pl-4 w-full md:justify-end mt-5  md:mt-0'
          />
        )}
      </div>
      <Container data={data} />
      <FastContainer data={data} />
    </Layout>
  );
};

export default SolutionSubcategoryTemplate;

export const pageQuery = graphql`
  query solutionSubcategoryQuery($slug: String!) {
    page: sanitySolutionSubcategory(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }

      modules {
        ...SolutionModules
      }

      title
      snippet
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      icon {
        ...ImageWithPreview
      }
      slug {
        current
      }
    }
    sanityHomeLoans {
      contactMethodDropdownOptions
      fundingDropdownOptions
      marketValueDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      requiredForDropdownOptions
      propertyTypeDropdownOptions
    }
    sanityBusinessLoans {
      contactMethodDropdownOptions
      financeTypeOptions
      industryDropdownOptions
      preferredPaymentDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      solutionTypeOptions
    }
    sanityFastLoans {
      alreadyPurchasedOptions
      businessClearCreditOptions
      contactMethodDropdownOptions
      directorsClearCreditOptions
      directorsPropertyOwners
      financeRequiredBy
      gstFundedOptions
      industryOptions
      isDirectorOptions
      legalStructureOptions
      positionOptions
      regionDropdownOptions
      repaymentOptions
      typeOfAssetOptions
      yearsOfOperationOptions
    }
  }
`;
