import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useSolutionQuery = () => {
  const { allSanitySolution } = useStaticQuery(graphql`
    query {
      allSanitySolution(sort: { fields: orderRank, order: ASC }) {
        solutions: nodes {
          title
          slug {
            current
          }
          snippet
          description: _rawDescription(resolveReferences: { maxDepth: 10 })
          icon {
            ...ImageWithPreview
          }
          carouselImage {
            ...Image
          }
          modules {
            modules {
              ... on SanityPageBanner {
                _key
                _type
                bgImage {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  `);
  return allSanitySolution || {};
};
